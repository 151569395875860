<template>
  <div>
    <informations-table
        :email-campaign="emailCampaign"
        :selected-customers="selectedCustomers"
        :selected-subscriptions="selectedSubscriptions"
        :selected-activities="selectedActivities"
        :selected-groups="selectedGroups"
    />
    <sent-list-table
        :email-campaign="emailCampaign"
    />
    <!-- To do clientsCount will be changed to targetCount -->
    <recipient-count
      :count="emailCampaign.targetCount"
    />
  </div>
</template>
<script>
  export default {
    props: {
      emailCampaign: {
        type: Object,
        default: () => {}
      },
      selectedCustomers: {
        type: Array,
        default: () => []
      },
      selectedSubscriptions: {
        type: Array,
        default: () => []
      },
      selectedActivities: {
        type: Array,
        default: () => []
      },
      selectedGroups: {
        type: Array,
        default: () => []
      }
    },
    components: {
      InformationsTable: () => import('./InformationsTable'),
      SentListTable: () => import('./SentListTable'),
      RecipientCount: () => import('./RecipientCount'),
    }
  }
</script>
